exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-landing-tsx": () => import("./../../../src/templates/BlogLanding.tsx" /* webpackChunkName: "component---src-templates-blog-landing-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-core-web-vitals-report-tsx": () => import("./../../../src/templates/CoreWebVitalsReport.tsx" /* webpackChunkName: "component---src-templates-core-web-vitals-report-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-job-profile-tsx": () => import("./../../../src/templates/JobProfile.tsx" /* webpackChunkName: "component---src-templates-job-profile-tsx" */),
  "component---src-templates-marketing-tsx": () => import("./../../../src/templates/Marketing.tsx" /* webpackChunkName: "component---src-templates-marketing-tsx" */),
  "component---src-templates-page-apply-for-job-tsx": () => import("./../../../src/templates/PageApplyForJob.tsx" /* webpackChunkName: "component---src-templates-page-apply-for-job-tsx" */),
  "component---src-templates-page-coming-soon-tsx": () => import("./../../../src/templates/PageComingSoon.tsx" /* webpackChunkName: "component---src-templates-page-coming-soon-tsx" */),
  "component---src-templates-page-contact-us-tsx": () => import("./../../../src/templates/PageContactUs.tsx" /* webpackChunkName: "component---src-templates-page-contact-us-tsx" */),
  "component---src-templates-page-event-tsx": () => import("./../../../src/templates/PageEvent.tsx" /* webpackChunkName: "component---src-templates-page-event-tsx" */),
  "component---src-templates-page-job-profile-tsx": () => import("./../../../src/templates/PageJobProfile.tsx" /* webpackChunkName: "component---src-templates-page-job-profile-tsx" */),
  "component---src-templates-page-machine-tsx": () => import("./../../../src/templates/PageMachine.tsx" /* webpackChunkName: "component---src-templates-page-machine-tsx" */),
  "component---src-templates-page-search-tsx": () => import("./../../../src/templates/PageSearch.tsx" /* webpackChunkName: "component---src-templates-page-search-tsx" */),
  "component---src-templates-page-thank-you-tsx": () => import("./../../../src/templates/PageThankYou.tsx" /* webpackChunkName: "component---src-templates-page-thank-you-tsx" */),
  "component---src-templates-pricing-tsx": () => import("./../../../src/templates/Pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-template-tsx": () => import("./../../../src/templates/Template.tsx" /* webpackChunkName: "component---src-templates-template-tsx" */)
}

